import { Component, OnInit } from '@angular/core';
import {Project} from '../../models/project';

declare var jQuery: any;

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss', '../home/bootstrap.css',  '../home/bootstrap.css', '../home/bootstrap-grid.css', '../home/bootstrap-reboot.css',]
})
export class ProjectDetailComponent implements OnInit {
  projectList: Project[] = [];
  constructor() { }
  myProjectTest: Project;
  ngOnInit() {


    // create and add a bunch of projects

    //completed
    this.projectList.push(new Project('/projects/humansdorpMain', '76 and 95 On Main Humansdorp', 'onMainHumansDorp1.jpg', 'Humansdorp, South Africa', 'onMainHumansDorp', 17));
    this.projectList.push(new Project('/projects/4AveNewton', '4th Avenue Newton', '4avenewton1.jpg', 'Port Elizabeth, South Africa', '4avenewton', 3));
    this.projectList.push(new Project('/projects/aspen', 'Aspen', 'Aspen1.jpg', 'Port Elizabeth, South Africa', 'Aspen', 12));
    this.projectList.push(new Project('/projects/audi', 'Audi Tavcor', 'Audi1.jpg', 'Port Elizabeth, South Africa', 'Audi', 5));
    this.projectList.push(new Project('/projects/beauRivage', 'Beau Rivage', 'beauRivage1.jpg', 'Port Elizabeth, South Africa', 'beauRivage', 1));
    this.projectList.push(new Project('/projects/borbet', 'Borbet', 'Borbet1.jpg', 'Port Elizabeth, South Africa', 'Borbet', 8));
    this.projectList.push(new Project('/projects/brooksviewPearls', 'Brooksview and Pearls', 'brooksviewPearls1.jpg', 'Port Elizabeth, South Africa', 'brooksviewPearls', 5));
    this.projectList.push(new Project('/projects/buildIt', 'Build It', 'buildIt1.jpg', 'Port Elizabeth, South Africa', 'buildIt', 3));
    this.projectList.push(new Project('/projects/engen', 'Engen', 'Engen3.jpg', 'South Africa', 'Engen', 4));
    this.projectList.push(new Project('/projects/eyelazer', 'Eye & Lazer', 'eyeLazer1.jpg', 'South Africa', 'eyeLazer', 4));
    this.projectList.push(new Project('/projects/ford', 'Ford', 'ford6.jpg', 'South Africa', 'ford', 6));
    this.projectList.push(new Project('/projects/geogios', 'Geogios', 'geogios1.jpg', 'South Africa', 'geogios', 2));
    this.projectList.push(new Project('/projects/greypavilion', 'Grey Junior Pavilion', 'greypavilion1.jpg', 'South Africa', 'greypavilion', 2));
    this.projectList.push(new Project('/projects/huntersRetreat', 'Hunters Retreat', 'huntersRetreat1.jpg', 'Port Elizabeth, South Africa', 'huntersRetreat', 5));
    this.projectList.push(new Project('/projects/kf', 'K&F', 'kf1.jpg', 'Port Elizabeth, South Africa', 'kf', 2));
    this.projectList.push(new Project('/projects/kingsLynn', 'Kings Lynn', 'kingsLynn6.jpg', 'Port Elizabeth, South Africa', 'kingsLynn', 8));
    this.projectList.push(new Project('/projects/makheta', 'Makheta', 'makheta4.jpg', 'Port Elizabeth, South Africa', 'makheta', 4));
    this.projectList.push(new Project('/projects/marula', 'Marula Game Lodge', 'marula5.jpg', 'Namibia', 'marula', 13));
    this.projectList.push(new Project('/projects/nandos', 'Nandos', 'nandos1.jpg', 'Port Elizabeth, South Africa', 'nandos', 1));
    this.projectList.push(new Project('/projects/priory', 'Priory', 'priory2.jpg', 'Port Elizabeth, South Africa', 'priory', 2));
    this.projectList.push(new Project('/projects/radisson', 'Radisson Blu', 'radisson1.jpg', 'Port Elizabeth, South Africa', 'radisson', 11));
    this.projectList.push(new Project('/projects/studio2000', 'Studio 2000', 'studio20001.jpg', 'Port Elizabeth, South Africa', 'studio2000', 6));
    this.projectList.push(new Project('/projects/sunnyside', 'Thrive Sunnyside Wellness centre', 'sunnyside1.jpg', 'Port Elizabeth, South Africa', 'sunnyside', 4));
    this.projectList.push(new Project('/projects/traxtion', 'Traxtion', 'traxtion1.jpg', 'Pretoria, South Africa', 'traxtion', 6));
    this.projectList.push(new Project('/projects/villageSquare', 'Village Square Shopping Centre', 'villageSquare1.jpg', 'St Francis, South Africa', 'villageSquare', 7));
    this.projectList.push(new Project('/projects/vw', 'Volkswagen Tavcor', 'vw1.jpg', 'Port Elizabeth, South Africa', 'vw', 4));
    this.projectList.push(new Project('/projects/vw', 'Woodlands Dairy', 'woodlands1.jpg', 'Humansdorp, South Africa', 'woodlands', 16));

  }

}
