import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-image-component',
  templateUrl: './image-component.component.html',
  styleUrls: ['./image-component.component.scss', '../project-detail/project-detail.component.scss', '../home/bootstrap.css',  '../home/bootstrap.css', '../home/bootstrap-grid.css', '../home/bootstrap-reboot.css']
})
export class ImageComponentComponent implements OnInit {
@Input()
imageName: string;
  constructor() { }

  ngOnInit() {
  }

}
