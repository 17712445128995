import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {ProjectDetailComponent} from './project-detail/project-detail.component';
import {SingleProjectComponent} from './single-project/single-project.component';
import {NavWrapperComponent} from './nav-wrapper/nav-wrapper.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: '', component: NavWrapperComponent, children: [
      { path: 'projects', component: ProjectDetailComponent, pathMatch: 'full'},
      { path: 'projects/:id', component: SingleProjectComponent}
    ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
