import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../../models/project';
import {Router} from '@angular/router';

@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss', '../home/bootstrap.css',  '../home/bootstrap.css', '../home/bootstrap-grid.css', '../home/bootstrap-reboot.css']
})
export class ProjectItemComponent implements OnInit {
@Input()
project: Project;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToProject(link: string) {
    this.router.navigate([link], {state: {project: this.project}});
  }

}
