import { Component, OnInit } from '@angular/core';
import {Project} from '../../models/project';
import {Router} from '@angular/router';

@Component({
  selector: 'app-single-project',
  templateUrl: './single-project.component.html',
  styleUrls: ['./single-project.component.scss', '../home/bootstrap.css',  '../home/bootstrap.css', '../home/bootstrap-grid.css', '../home/bootstrap-reboot.css']
})
export class SingleProjectComponent implements OnInit {
  project: Project;
  imagelist: string[] = [];
  constructor(private router: Router) { }

  ngOnInit() {
    this.project = history.state.project;
    if(this.project === null || this.project === undefined) {
      this.router.navigate(['/projects']);
    }
    //generate image list
    for(var i = 1; i < this.project.nrImages; i++) {
      this.imagelist.push(this.project.idName + '/' + this.project.idName + i.toString() + '.jpg') ;
    }



  }
}
