import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ProjectItemComponent } from './project-item/project-item.component';
import { SingleProjectComponent } from './single-project/single-project.component';
import { ImageComponentComponent } from './image-component/image-component.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NavWrapperComponent } from './nav-wrapper/nav-wrapper.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectDetailComponent,
    ProjectItemComponent,
    SingleProjectComponent,
    ImageComponentComponent,
    NavWrapperComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
