export class Project {
  link: string;
  name: string;
  location: string;
  imageArray: string[];
  featureImage: string;
  idName: string;
  nrImages: number;

  constructor(link: string, name: string, featureImage: string, location: string, idName: string, nrImages: number) {
    this.link = link;
    this.name = name;
    this.location = location;
    this.featureImage = featureImage;
    this.idName = idName;
    this.nrImages = nrImages;
  }
}
