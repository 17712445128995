import {Component, OnInit} from '@angular/core';
import {Project} from '../../models/project';

declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  // tslint:disable-next-line:max-line-length
  styleUrls: ['./home.component.scss', './bootstrap.css', './bootstrap-grid.css', './bootstrap-reboot.css', './home.css', './home-slider.css']
})
export class HomeComponent implements OnInit {
  highlightProjectList: Project[] = [];
  constructor() {
  }

  ngOnInit() {
    this.onLoadScripts();
    this.heightScript();

    this.highlightProjectList.push(new Project('/projects/aspen', 'Aspen', 'Aspen1.jpg', 'Port Elizabeth, South Africa', 'Aspen', 12));
    this.highlightProjectList.push(new Project('/projects/audi', 'Audi Tavcor', 'Audi4.jpg', 'Port Elizabeth, South Africa', 'Audi', 5));
    this.highlightProjectList.push(new Project('/projects/borbet', 'Borbet', 'Borbet1.jpg', 'Port Elizabeth, South Africa', 'Borbet', 8));
    this.highlightProjectList.push(new Project('/projects/radisson', 'Radisson Blu', 'radisson1.jpg', 'Port Elizabeth, South Africa', 'radisson', 11));
    this.highlightProjectList.push(new Project('/projects/vw', 'Volkswagen Tavcor', 'vw1.jpg', 'Port Elizabeth, South Africa', 'vw', 4));
    this.highlightProjectList.push(new Project('/projects/woodlands', 'Woodlands Dairy', 'woodlands1.jpg', 'Humansdorp, South Africa', 'woodlands', 16));
  }

  onLoadScripts() {
    (function($) {
      $(document).ready(function() {
        var distance = $('#original-nav').offset().top,
          $window = $(window);
        var $paragraph = $('p');
        $(window).scroll(function() {
          if ($window.scrollTop() >= distance) {
            // Your div has reached the top
            $('#fix-nav').css('visibility', 'visible');
          } else {
            $('#fix-nav').css('visibility', 'hidden');
          }
          $paragraph.each(function() {
            var paragraphMiddle = $(this).offset().top + (0.5 * $(this).height());
            var windowBottom = $(window).scrollTop() + $(window).height();
            if (paragraphMiddle < windowBottom) {
              $(this).addClass('paraFadeIn');
            }
          });
        });
        /* On Load: Trigger Scroll Once*/
        $(window).scroll();
        $('.large-text').css('transform', 'translateY(' + (-10) + '%)');
        /* Every time the window is scrolled ... */
        $(window).scroll(function() {
          /* Check the location of each desired element */
          $('.project-card-container').each(function(i) {
            var bottom_of_object = $(this).position().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            /* If the object is completely visible in the window, fade it it */
            if (bottom_of_window > bottom_of_object) {
              $(this).animate({'opacity': '1'}, 1500);
            }
          });
        });
        $('#btn_about_us').click(function() {
          $([document.documentElement, document.body]).animate({
            scrollTop: $('#about-us').offset().top
          }, 1000);
        });

        $('#btn_projects').click(function() {
          $([document.documentElement, document.body]).animate({
            scrollTop: $('#projects').offset().top
          }, 1000);
        });
        $('#btn_founders').click(function() {
          $([document.documentElement, document.body]).animate({
            scrollTop: $('#founders').offset().top
          }, 1000);
        });
        $('#btn_contact_us').click(function() {
          $([document.documentElement, document.body]).animate({
            scrollTop: $('#contact-us').offset().top
          }, 1000);
        });
      });
      $(window).on('scroll', function() {
        var trainPosition = Math.round($(window).scrollTop() / $(window).height() * 5);
        $('.large-text').css('transform', 'translateY(' + (trainPosition) + '%)');
      });
      $(document).ready(function() {
      });
      $(document).ready(function() {
        // work in progress - needs some refactoring and will drop JQuery i promise :)
        const slide = {
          main: null,
          elementImg: null,
          imgSelected: 0,
          nextSlide: function() {
            if (this.imgSelected != null) {
              if (this.imgSelected < (this.elementImg.length - 1)) {
                this.imgSelected++;
                this.normalizeSlide();
              }
            }
          },
          prevSlide: function() {
            if (this.imgSelected != null) {
              if (this.imgSelected > 0) {
                this.imgSelected--;
                this.normalizeSlide();
              }
            }
          },
          normalizeSlide: function() {

            for (var num = 0; num < this.elementImg.length; num++) {
              this.elementImg[num].classList.remove('hideLeft', 'prevLeftSecond', 'prev', 'selected', 'next', 'nextRightSecond', 'hideRight');
            }

            this.elementImg[this.imgSelected].classList.add('selected');

            if (this.imgSelected > 2) {
              this.elementImg[this.imgSelected - 2].classList.add('hideLeft');
              this.elementImg[this.imgSelected - 2].classList.add('prevLeftSecond');
              this.elementImg[this.imgSelected - 1].classList.add('prev');
            } else if (this.imgSelected > 1) {
              this.elementImg[this.imgSelected - 2].classList.add('prevLeftSecond');
              this.elementImg[this.imgSelected - 1].classList.add('prev');
            } else if (this.imgSelected > 0) {
              this.elementImg[this.imgSelected - 1].classList.add('prev');
            }

            if ((this.imgSelected + 3) < this.elementImg.length) {
              this.elementImg[this.imgSelected + 3].classList.add('hideRight');
              this.elementImg[this.imgSelected + 2].classList.add('nextRightSecond');
              this.elementImg[this.imgSelected + 1].classList.add('next');
            } else if ((this.imgSelected + 2) < this.elementImg.length) {
              this.elementImg[this.imgSelected + 2].classList.add('nextRightSecond');
              this.elementImg[this.imgSelected + 1].classList.add('next');
            } else if ((this.imgSelected + 1) < this.elementImg.length) {
              this.elementImg[this.imgSelected + 1].classList.add('next');
            }
          }
        };
        window.onload = () => {
          slide.main = document.getElementById('carousel');
          slide.elementImg = slide.main.getElementsByClassName('slideImg');

          for (var num = 0; num < slide.elementImg.length; num++) {
            slide.elementImg[num].setAttribute('img-number', num);

            slide.elementImg[num].addEventListener('click', (event) => {
              slide.imgSelected = parseInt(event.target.parentElement.getAttribute('img-number'));
              slide.normalizeSlide();
            });

            if (slide.elementImg[num].classList.contains('selected')) {
              slide.imgSelected = num;
            }
          }
          document.getElementById('prev').addEventListener('click', () => {
            slide.prevSlide();
          });
          document.getElementById('next').addEventListener('click', () => {
            slide.nextSlide();
          });
        };
      });
    })(jQuery);
  }

  heightScript() {
    (function($) {
      $(document).ready(function() {
        var leftDiv = $('#left-info-block').height();
        var midDiv = $('#mid-info-block').height();
        var rightDiv = $('#right-info-block').height();
        if (leftDiv >= midDiv && leftDiv >= rightDiv) {
          document.getElementById('mid-info-block').style.height = leftDiv + 'px' ;
          document.getElementById('right-info-block').style.height =  leftDiv + 'px';
          document.getElementById('left-info-block').style.height =  leftDiv + 'px';
        } else {
          if (midDiv >= leftDiv && midDiv >= rightDiv) {
            document.getElementById('left-info-block').style.height =   midDiv + 'px';
            document.getElementById('right-info-block').style.height = midDiv + 'px';
            document.getElementById('mid-info-block').style.height = midDiv + 'px';
          } else {
            if (rightDiv >= leftDiv && rightDiv >= midDiv) {
              document.getElementById('left-info-block').style.height = rightDiv + 'px';
              document.getElementById('mid-info-block').style.height = rightDiv + 'px';
              document.getElementById('right-info-block').style.height = rightDiv + 'px';
            }
          }
        }
      });
    })(jQuery);
  }

}
